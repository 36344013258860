<template>
  <main class="container my-40">
    <div class="row">
      <div class="col-md-12 mb-60">
        <div class="row d-flex justify-content-center" @click="resetForm()">
          <div
            class="col-md-6 label d-flex justify-content-center"
            style="cursor: pointer"
          >
            <Label
              color="orange"
              text="POSTULA A ENTREVISTAS AQUÍ"
              fontSize="30px !important"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-40">
        <div class="d-flex align-items-center mb-20">
          <h3>PERFIL DEL POSTULANTE</h3>
          <span class="uc-heading-decoration"></span>
        </div>
        <p class="mt-5">
          La Feria del Trabajo Derecho UC es la mayor Feria legal que se realiza
          en el país y con el paso de los años se ha consolidado como un
          verdadero puente entre los mejores empleadores, alumnos y abogados del
          país. Esta plataforma no sólo entrega las herramientas para una
          adecuada planificación de carrera, sino que pone a disposición de los
          visitantes la más amplia oferta laboral del mundo legal, tanto del
          sector público como privado, facilitando la realización de entrevistas
          de reclutamiento.
        </p>
        <p class="mt-5">
          La Feria permite que el estudiante y abogado pueda explorar aquellas
          áreas que son de su mayor interés para así ir desarrollándose en el
          mundo del derecho.
        </p>
      </div>
      <div class="col-md-6 mb-40">
        <div class="d-flex align-items-center mb-20">
          <h3>ENTREVISTAS</h3>
          <span class="uc-heading-decoration"></span>
        </div>
        <p class="mt-5">
          Una vez cerrada la inscripción de los expositores se abre el periodo
          de inscripción a entrevistas para los visitantes de la Feria, dirigida
          a alumnos, egresados, licenciados y abogados de las principales
          Facultades de Derecho del país. ¡Solo hasta el
          {{ applicationDeadline }}! No pierdas la oportunidad de inscribirte y
          postular a las entrevistas programadas con los mejores empleadores del
          mercado. No olvides que puedes asistir a la Feria el día 28 aunque no
          quedes seleccionado, además de ingresar al stand virtual de cada uno
          de ellos a dejar sus antecedentes.
          <strong
            >La inscripción no asegura que quedarás en una entrevista
            programada.</strong
          >
        </p>
      </div>
    </div>
    <Modal
      :type="modal.type"
      :title="modal.title"
      v-if="showModal"
      :body="modal.body"
      :closeButton="modal.closeButton"
    >
      <template slot="body" v-if="fairEnable">
        <ApplyForm :clear="showModal" v-show="!modal.formSent" />
        <ApplyConfirm v-show="modal.confirmData" :fields="formData" />
      </template>
      <template slot="footer" v-if="fairEnable">
        <div class="col-md-12 text-right" v-show="!modal.formSent">
          <button class="uc-btn btn-primary btn-inline" @click="send">
            {{ sendText }}
            <i class="uc-icon icon-shape--rounded">send</i>
          </button>
        </div>
        <div class="col-md-12 text-right" v-show="modal.confirmData">
          <button class="uc-btn btn-primary error btn-inline" @click="cancel">
            Cancelar
            <i class="uc-icon icon-shape--rounded">backspace</i>
          </button>
          <button class="uc-btn btn-primary btn-inline" @click="confirm">
            {{ confirmText }}
            <i class="uc-icon icon-shape--rounded">send</i>
          </button>
        </div>
      </template>
    </Modal>
  </main>
</template>

<script>
import Label from "@/components/Label.vue";
import Modal from "@/components/Modal.vue";
import ApplyForm from "@/views/ApplyForm.vue";
import ApplyConfirm from "@/views/ApplyConfirm.vue";
import axios from "axios";
import { formValidation } from "@/mixins/formValidation.js";
import { mapMutations } from "vuex";

export default {
  components: {
    Label,
    Modal,
    ApplyConfirm,
    ApplyForm,
  },
  mixins: [formValidation],
  data() {
    return {
      applicationDeadline: false,
      showModal: false,
      sendText: "Enviar",
      confirmText: "Confirmar",
      formData: {},
      fairEnable: false,
      modal: {
        body: "",
        closeButton: false,
        confirmData: false,
        formSent: false,
        title: "Formulario Inscripción Entrevistas",
        type: "info",
      },
    };
  },
  mounted() {
    require("@digitaluc/uc-kitdigital/dist/js/uc-kitdigital");
  },
  beforeCreate() {
    axios
      .get(`${process.env.VUE_APP_REDES_ENDPOINT}/fairs/status`, {
        withCredentials: false,
      })
      .then((response) => {
        this.fairEnable = response.data.enable;
        this.applicationDeadline = response.data.application_deadline;

        if (!this.fairEnable) {
          this.modal.body = response.data.message;
          this.modal.closeButton = true;
          this.modal.formSent = false;
          this.modal.confirmData = false;
          this.modal.type = "warning";
          this.modal.title = "Información Inscripciones";
        }
      });
  },
  methods: {
    send() {
      this.sendText = "Enviando...";

      let data = {};
      this.$store.commit("applyForm/resetRequired");

      const fields = this.$store.state.applyForm.fields;
      const required = { ...this.$store.state.applyForm.required };
      const requiredTooltip = {
        ...this.$store.state.applyForm.requiredTooltip,
      };

      for (const key in fields) {
        data[key] = fields[key];
      }

      if (this.checkForm(data, required, requiredTooltip)) {
        this.$store.commit("applyForm/required", { required, requiredTooltip });
        this.sendText = "Enviar";

        return;
      }

      for (const field in data) {
        if (data[field] === "emptyValue" || data[field] === null) {
          delete data[field];
        }
      }
      this.modal.formSent = true;
      this.modal.confirmData = true;
      this.formData = data;
    },
    resetForm() {
      mapMutations("applyForm", ["resetForm"]);
      this.showModal = true;
      if (this.fairEnable) {
        this.modal.body = "";
        this.modal.closeButton = false;
        this.modal.formSent = false;
        this.modal.type = "info";
      }
    },
    cancel() {
      this.modal.formSent = false;
      this.modal.confirmData = false;
      this.sendText = "Enviar";
    },
    confirm() {
      if (
        typeof this.formData == "undefined" ||
        !Object.keys(this.formData).length
      ) {
        return;
      }

      this.confirmText = "Enviando...";

      axios
        .post(
          `${process.env.VUE_APP_REDES_ENDPOINT}/applicants`,
          this.formData,
          {
            withCredentials: false,
          }
        )
        .then((response) => {
          this.modal.body = response.data.message;
          this.modal.type = "warning";

          if (response.data.success) {
            this.modal.formSent = true;
            this.modal.closeButton = true;
            this.modal.body = response.data.message;
            this.modal.type = "success";
          }
        })
        .catch((error) => {
          switch (error.response.status) {
            case 409:
              this.modal.formSent = true;
              this.modal.closeButton = true;
              this.modal.body = error.response.data.message;
              this.modal.type = "warning";
              break;
            default:
              this.modal.formSent = true;
              this.modal.closeButton = true;
              this.modal.body = error.response.data.message;
              this.modal.type = "error";
              break;
          }
        })
        .then(() => {
          this.confirmText = "Confirmar";
          this.modal.confirmData = false;
          this.sendText = "Enviar";
        });
    },
  },
};
</script>
