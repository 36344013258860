<template>
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="check"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    class="svg-inline--fa fa-check fa-w-16 fa-2x check"
  >
    <path
      :fill="getHex()"
      d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
      class
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: String,
  },
  methods: {
    getHex() {
      switch (this.color) {
        case "green":
          return "#9ACA33";
        case "orange":
          return "#FC9728";
        case "blue":
          return "#006EB6";
      }
    },
  },
};
</script>

<style>
svg.check {
  background: linear-gradient(
    90deg,
    rgba(222, 26, 147, 1) 0%,
    rgba(88, 6, 84, 1) 100%
  );
  padding: 10px;
  height: 50px;
  width: 50px;
}
</style>
